import React, { useState } from 'react'
import { render } from 'react-dom'
import styled, { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  
  html, body, #app {
    font-family: "Rokkitt", serif;
    height: 100vh;
    margin: 0;
    padding: 0;
    position: relative;
  }
`

const Center = styled.main`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  justify-content: space-around;
  padding: 16px;
`

const Textarea = styled.textarea`
  height: 120px
  width: 100%;
`

const numeronym = word => word.length > 1 ? `${word[0]}${word.length-2}${word[word.length-1]}` : `${word}`

const App = () => {
  const [numeronyms, setNumeronyms] = useState('')

  function handleChange (event) {
    if (event.target.value.trim().length) {
      setNumeronyms(event.target.value.trim().split(' ').map(numeronym).join(' '))
    } else {
      setNumeronyms('')
    }
  }

  return (
    <>
      <GlobalStyle/>
      <Center>
        <h1>{ numeronyms }</h1>
        <Textarea type='text' onChange={ handleChange }/>
      </Center>
    </>
  )
}

render(
  <App/>
  , document.getElementById('app')
)
